define(['santa-components', 'wixappsCore', 'lodash', 'coreUtils', 'wixappsBuilder/util/fieldProxyUtils'],
    function (santaComponents, /** wixappsCore */wixapps, _, coreUtils, fieldProxyUtils) {
        'use strict';

        function getLinkData() {
        // get the data links from the item
            const item = this.getCompProp('items')[0];
            if (item.link) {
                return item.link;
            }

            // this is the field in the data context, that will appear in the links map
            const fieldName = this.getViewDefProp('data', item) || this.getViewDefProp('data');

            return this.getDataByPath(`links.${fieldName}`);
        }

        function getChildViewDef() { // eslint-disable-line complexity
            const data = this.proxyData;
            const childViewDef = _.cloneDeep(this.props.viewDef);
            const childCompDef = childViewDef.comp;

            const items = this.getCompProp('items', childViewDef);

            if (!items || items.length !== 1) {
                throw new Error('Field proxy accepts exactly one child');
            }

            const fieldOrientation = this.props.orientation;
            const isVertical = fieldOrientation === 'vertical';

            if (this.adjustViewDefs) {
                this.adjustViewDefs(childViewDef, items[0]);
            }

            childCompDef.name = isVertical ? 'VBox' : 'HBox';
            childCompDef.hidden = this.shouldHide(data);

            // apply the item layout on the original item, if exists
            const itemLayout = this.getItemLayout();
            if (_.keys(itemLayout).length) {
                items[0].layout = _.merge(items[0].layout || {}, itemLayout);
            }

            const linkViewDef = fieldProxyUtils.getLinkViewDef(this.getCompProp('pageLink'), getLinkData.call(this));

            // wrap the single item with link proxy if has link
            if (linkViewDef) {
                linkViewDef.layout = _.cloneDeep(items[0].layout);
                //delete items[0].layout;
                // ugly code ahead
                linkViewDef.comp.items.push(items.pop());
                items.push(linkViewDef);
            }

            // apply the spacers on the link if exist or on the original item if wasn't switched
            const spacers = fieldProxyUtils.getSpacers(fieldOrientation, this.getCompProp('spacers'), this.getVar('partDirection'));

            childViewDef.layout = _.merge(childViewDef.layout || {}, spacers);

            return childViewDef;
        }

        /**
     * @class proxies.mixins.baseField
     * @extends proxies.mixins.baseComposite
     * @property {proxy.properties} props
     * @property {function(): object} getFieldCustomStyle
     */
        return {
            mixins: [wixapps.baseCompositeProxy],

            getCustomStyle() {
                const style = {};
                if (this.getCompProp('box-align')) {
                    style['box-align'] = this.getCompProp('box-align');
                }

                return style;
            },

            renderProxy() {
                const childViewDef = getChildViewDef.call(this);
                const props = this.getChildCompProps();

                // We should use the opposite direction of the fieldBox
                if (this.props.orientation === 'horizontal') {
                    const classes = {
                        flex_display: true,
                        flex_vbox: true
                    };
                    if (props.className) {
                        classes[props.className] = true;
                    }
                    props.className = coreUtils.classNames(classes);
                }

                return santaComponents.utils.createReactElement('div', props, this.renderChildProxy(childViewDef));
            }
        };
    });
