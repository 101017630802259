define([
    'lodash',
    'santa-components',
    'componentsCore',
    'wixappsBuilder/core/appBuilderSantaTypesDefinitions',
    'wixappsBuilder/core/appPart2StyleCollector',
    'wixappsCore',
    'wixappsBuilder/util/appbuilderUrlUtils',
    'wixappsBuilder/util/fontUtils',
    'wixappsBuilder/logics/appPart2Presenter'
], function (_,
             santaComponents,
             componentsCore,
             appBuilderSantaTypesDefinitions,
             appPart2StyleCollector,
             wixappsCore,
             appsUrlUtils,
             fontUtils,
             AppPart2Presenter) {
    'use strict';

    const wixappsLogger = wixappsCore.wixappsLogger;

    function reportToBI(isError, reportDef, params) {
        if (isError) {
            this.props.reportError(reportDef, params);
        } else {
            this.props.reportEvent(reportDef, params);
        }
    }

    function isRepoValid(props) {
        if (!props.appPartDefinition || !props.dataSelector) {
            this.reportToBI(true, wixappsLogger.errors.APP_PART2_FAILED_TO_LOAD);
            return false;
        }

        return true;
    }

    function getCssState(props, state) {
        const metadata = props.compMetaData;
        if (metadata.loading || !props.isRequestSent || this.props.didPackageHadRequestsTimeoutInSSR && this.props.isInSSR) { // eslint-disable-line no-mixed-operators
            return {$displayMode: 'loading'};
        } else if (metadata.error) {
            return {$displayMode: 'error'};
        }

        const cssState = {
            $displayMode: 'content',
            itsDeadJim: !isRepoValid.call(this, props)
        };

        if (cssState.$displayMode !== state.$displayMode) {
            props.registerReLayoutPending(props.id);
        }

        return cssState;
    }

    function updatePageTitleIfNeeded() {
        if (_.get(this.props.dataSelectorDefinition, 'logicalTypeName') === 'IB.PageSelectedItem') {
            const item = this.getDataByFullPath(this.props.partDataLocation);
            const title = `${item && item.title || ''} | ${this.props.appPartDefinition.displayName}`; // eslint-disable-line no-mixed-operators
            this.props.setPageTitle(_.unescape(title), title);
        }
    }

    const LOCALIZATION_BUNDLE = {'FILTER_DIALOG_All_Tags': 'All'};
    const getCustomCss = wixappsCore.appPartCustomCss.getCustomCss(appPart2StyleCollector);

    const getCustomCompFonts = ({partData, descriptor}) => _.union(fontUtils.getFontFamiliesFromTextData(partData), fontUtils.getCustomFontFamilies(descriptor));
    const fontsTypes = {
        partData: appBuilderSantaTypesDefinitions.partData,
        descriptor: wixappsCore.CoreSantaTypes.descriptor
    };
    getCustomCompFonts.fontsTypes = fontsTypes;
    /**
     * @class components.AppPart2
     * @extends {core.skinBasedComp}
     * @extends {wixapps.viewsRenderer}
     */
    return {
        displayName: 'AppPart2',
        mixins: [wixappsCore.viewsRenderer, componentsCore.mixins.customSkinBasedComp({customCssFunc: getCustomCss, customFontsFunc: getCustomCompFonts})],
        propTypes: {
            isInSSR: santaComponents.santaTypesDefinitions.isInSSR.isRequired,
            didPackageHadRequestsTimeoutInSSR: appBuilderSantaTypesDefinitions.didPackageHadRequestsTimeoutInSSR.isRequired,
            userId: santaComponents.santaTypesDefinitions.RendererModel.userId,
            setPageTitle: wixappsCore.CoreSantaTypes.setPageTitle.isRequired,
            reportEvent: wixappsCore.CoreSantaTypes.reportEvent.isRequired,
            reportError: wixappsCore.CoreSantaTypes.reportError.isRequired,
            descriptor: wixappsCore.CoreSantaTypes.descriptor,
            isRequestSent: appBuilderSantaTypesDefinitions.isRequestSent.isRequired,
            partData: appBuilderSantaTypesDefinitions.partData,
            partDataLocation: appBuilderSantaTypesDefinitions.partDataLocation,
            appPartDefinition: appBuilderSantaTypesDefinitions.appPartDefinition,
            views: appBuilderSantaTypesDefinitions.views,
            dataSelector: appBuilderSantaTypesDefinitions.dataSelector,
            dataSelectorDefinition: appBuilderSantaTypesDefinitions.dataSelectorDefinition,
            compMetaData: appBuilderSantaTypesDefinitions.compMetadata.isRequired,
            registerReLayoutPending: santaComponents.santaTypesDefinitions.Layout.registerReLayoutPending.isRequired
        },

        getInitialState() {
            this.reportToBI = _.once(reportToBI.bind(this));
            this.partApi = this.getPartApi();
            this.logic = new AppPart2Presenter(this.partApi);
            const cssState = getCssState.call(this, this.props, {});
            if (cssState.$displayMode === 'content' && !cssState.itsDeadJim) {
                updatePageTitleIfNeeded.call(this);
            }

            return cssState;
        },

        componentWillReceiveProps(nextProps) {
            if (this.state.itsDeadJim) {
                return;
            }

            this.partApi.updateProps(nextProps);
            const cssState = getCssState.call(this, nextProps, this.state);
            if (cssState.$displayMode === 'content' && !cssState.itsDeadJim) {
                updatePageTitleIfNeeded.call(this);
            }
            this.props.registerReLayoutPending(this.props.id);
            if (!_.isEqual(this.state, cssState)) {
                this.setState(cssState);
            }
        },

        getPartApi() {
            let props = this.props;
            return {
                updateProps(nextProps) {
                    props = nextProps;
                },
                reportEvent() {
                    return props.reportEvent;
                },
                getAppPartDefinition() {
                    return props.appPartDefinition;
                },
                getRepo() {
                    return props.descriptor;
                },
                getPartData() {
                    return props.partDataLocation;
                },
                getDataAspect: this.getDataAspect,
                getLocalizationBundle() {
                    return LOCALIZATION_BUNDLE;
                },
                getDataSelector() {
                    return props.dataSelector;
                },
                registerReLayout() {
                    return props.registerReLayoutPending;
                },
                setCssState: this.setCssState
            };
        },

        setCssState(state) {
            if (this.state.$displayMode !== state) {
                this.props.registerReLayoutPending(this.props.id);
            }
            this.setState({$displayMode: state});
        },

        getViewDef(viewName, typeName) {
            return this.props.views[typeName];
        },

        getViewName() {
            return this.props.appPartDefinition.viewName;
        },

        resolveImageData: appsUrlUtils.resolveImageData,

        getSkinProperties() {
            if (this.state.itsDeadJim) {
                throw new Error('AppPart data is not valid.');
            }

            if (this.state.$displayMode === 'content') {
                const content = this.renderView();
                const styleWithoutHeight = _.clone(this.props.style || {});
                styleWithoutHeight.height = 'auto';

                this.reportToBI(false, wixappsLogger.events.APP_BUILDER_PART_LOADED, {
                    appPartName: this.props.compData.appPartName,
                    userId: this.props.userId
                });

                return {
                    '': {
                        style: styleWithoutHeight,
                        'data-dynamic-height': true
                    },
                    inlineContent: {
                        children: content,
                        style: {
                            height: 'auto'
                        }
                    }
                };
            }

            return {};
        }
    };
});
