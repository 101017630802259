define(['wixappsBuilder/proxies/mixins/baseFieldProxy', 'lodash'],
    function (baseFieldProxy, _) {
        'use strict';

        const defaultManualWidth = 150;
        const defaultManualHeight = 150;

        const typeEmptyCheckers = {
            'wix:RichText'(data) {
                return !data.text;
            },
            'String'(data) {
                return !data;
            },
            'wix:Date'(data) {
                return !data.iso;
            }
        };

        function getHeightLayout() { // eslint-disable-line complexity
            const heightMode = this.getCompProp('height-mode') || 'auto';
            const minHeight = this.getCompProp('min-height') || 100;
            const height = this.getCompProp('height');

            if (heightMode === 'auto') {
                return {height: 'auto'};
            } else if (heightMode === 'max-chars') {
                return {'min-height': minHeight};
            } else if (heightMode === 'fixed-height') {
                return {
                    'min-height': 0,
                    height: height || defaultManualHeight,
                    'overflow-y': 'hidden'};
            } else if (heightMode === 'pixels') {
                return {height: height || defaultManualHeight};
            }

            return {};
        }

        function getWidthLayout() {
            const isManualWidth = this.getCompProp('width-mode') === 'manual';
            const width = this.getCompProp('width');
            const fieldOrientation = this.props.orientation;

            if (isManualWidth) {
                return {width: width || defaultManualWidth};
            } else if (fieldOrientation === 'vertical') {
                return {width: '100%'};
            }
            return {'box-flex': '1 1 auto'};
        }

        function adjustItemViewDef(itemViewDef) { // eslint-disable-line complexity
            const heightMode = this.getCompProp('height-mode') || 'auto';
            const minLines = this.getCompProp('min-lines') || 0;
            let maxLines = this.getCompProp('max-lines') || 0;
            if (maxLines > 0 && maxLines < minLines) {
                maxLines = minLines;
            }

            const maxChars = this.getCompProp('max-chars') || 100;

            itemViewDef.comp.name = this.proxyData && this.proxyData._type === 'wix:Date' ? 'Date' : 'Label';
            if (heightMode === 'auto') {
                itemViewDef.comp.singleLine = false;
            } else if (heightMode === 'lines') {
                if (minLines === 0 && maxLines === 1 && _.isString(this.proxyData)) {
                    itemViewDef.comp.singleLine = true;
                } else if (maxLines > 0) {
                    itemViewDef.comp.name = 'ClippedParagraph';
                    itemViewDef.comp.minLines = minLines;
                    itemViewDef.comp.maxLines = maxLines;
                }
            } else if (heightMode === 'max-chars') {
                itemViewDef.comp.name = 'ClippedParagraph2';
                itemViewDef.comp['max-chars'] = maxChars;
            } else if (heightMode === 'pixels') {
                itemViewDef.comp.name = 'ClippedParagraph';
                itemViewDef.comp.minLines = 0;
                itemViewDef.comp.maxLines = 0;
            }
        }

        function adjustViewDef(viewDef) {
            const fieldOrientation = this.props.orientation;

            if (fieldOrientation === 'horizontal') {
                viewDef.layout = viewDef.layout || {};
                viewDef.layout['max-width'] = this.getCompProp('width') || defaultManualWidth;
            }
        }

        /**
         * @class proxies.textField
         * @extends proxies.mixins.baseField
         * @property {proxy.properties} props
         */
        return {
            mixins: [baseFieldProxy],

            shouldHide(data) {
                if (_.isUndefined(data)) {
                    return true;
                }
                const typeName = _.isString(data) ? 'String' : data._type;
                const checker = typeEmptyCheckers[typeName];
                return checker && checker(data) && (this.getCompProp('min-lines') || 0) === 0;
            },

            adjustViewDefs(viewDef, itemViewDef) {
                adjustViewDef.call(this, viewDef);
                adjustItemViewDef.call(this, itemViewDef);
            },

            getItemLayout() {
                return _.merge(getWidthLayout.call(this), getHeightLayout.call(this));
            }
        };
    });
