define([
    'componentsCore',
    'wixappsCore',
    'wixappsBuilder/comps/appPart2',
    'wixappsBuilder/proxies/fieldBoxProxy',
    'wixappsBuilder/proxies/fieldProxy',
    'wixappsBuilder/proxies/textFieldProxy',
    'wixappsBuilder/core/appRepo',
    'wixappsBuilder/core/builderDataHandler',
    'wixappsBuilder/core/appPart2DataFetchingStateManager',
    'wixappsBuilder/util/viewsTemplatesUtils',
    'wixappsBuilder/util/viewsTemplatesData.json',
    'wixappsBuilder/util/fieldBoxProxyUtils',
    'wixappsBuilder/util/appbuilderUrlUtils',
    'wixappsBuilder/core/appBuilderSantaTypes',
    'wixappsBuilder/core/appBuilderSantaTypesDefinitions',

    // side effects only.
    'wixappsBuilder/util/fontUtils',
    'wixappsBuilder/core/appPart2DataRequirementsChecker',
    'wixappsBuilder/core/appPart2PreviewDataRequirementsChecker',
    'wixappsBuilder/core/appPart2StyleCollector'
], function (/** core */componentsCore, /** wixappsCore */wixapps, appPart2, fieldBox, field, textField, appRepo, builderDataHandler,
                        appPart2DataFetchingStateManager, viewsTemplatesUtils, viewsTemplatesData, fieldBoxProxyUtils, appbuilderUrlUtils,
                        appBuilderSantaTypes, appBuilderSantaTypesDefinitions) {
    'use strict';

    componentsCore.compRegistrar.register('wixapps.integration.components.AppPart2', appPart2);
    componentsCore.santaTypesFetchersRegistry.registerFetchersNamespace('listBuilderSantaTypes', appBuilderSantaTypes);

    wixapps.proxyFactory.register('FieldBox', fieldBox);
    wixapps.proxyFactory.register('Field', field);
    wixapps.proxyFactory.register('TextField', textField);


    /**
     * @class wixappsBuilder
     */
    return {
        appBuilderSantaTypes,
        appBuilderSantaTypesDefinitions,
        appRepo,
        builderDataHandler,
        dataFetchingStateManager: appPart2DataFetchingStateManager,
        viewsTemplatesUtils,
        fieldBoxProxyUtils,
        viewsTemplatesData,
        resolveImageData: appbuilderUrlUtils.resolveImageData
    };
});
