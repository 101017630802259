define(['lodash', 'coreUtils', 'wixappsBuilder/util/oldImagesConversionMap'], function (_, coreUtils, oldImagesConversionMap) {
    'use strict';

    const NON_MEDIA_URL = /^(http:\/\/)?(images\/.*)/;

    function resolveImageData(data, serviceTopology) {
        data = fixNonMediaServiceImage(data);

        const appBasedPath = _.get(serviceTopology, 'scriptsLocationMap.wixapps');
        const match = NON_MEDIA_URL.exec(data.src);
        if (match && appBasedPath) {
            data.src = coreUtils.urlUtils.joinURL(appBasedPath, match[2]);
        }

        return data;
    }

    /**
     * Replace old images that were part of the wixapps artifact to images from the media server.
     * @param data
     * @returns {*}
     */
    function fixNonMediaServiceImage(data) {
        if (NON_MEDIA_URL.test(data.src)) {
            const imageFileName = _.last(data.src.split('/'));
            const convertedImage = oldImagesConversionMap[imageFileName];
            if (convertedImage) {
                return _.assign({}, data, convertedImage);
            }
        }

        return _.clone(data);
    }

    return {
        resolveImageData
    };
});
