define([
    'lodash',
    'santa-components',
    'core',
    'wixappsCore',
    'wixappsBuilder/core/appRepo',
    'wixappsBuilder/core/appBuilderSantaTypesDefinitions',
    'wixappsBuilder/core/appPart2DataFetchingStateManager'
], function (
    _,
    santaComponents,
    core,
    /** wixappsCore */ wixapps,
    appRepo,
    appBuilderSantaTypesDefinitions,
    dataFetchingStateManager
) {
    'use strict';

    function addAppPartStyles({getStyleData, views, loadedStyles = {}}) {
        _.forEach(views, function (viewDef) {
            if (viewDef) {
                wixapps.styleCollector.collectViewStyles(viewDef, getStyleData, loadedStyles);
            }
        });

        wixapps.styleCollector.addDefaultStyles(getStyleData, loadedStyles);

        return loadedStyles;
    }

    addAppPartStyles.cssTypes = {
        views: appBuilderSantaTypesDefinitions.views,
        getStyleData: santaComponents.santaTypesDefinitions.Component.getStyleData
    };

    core.styleCollector.registerClassBasedStyleCollector('wixapps.integration.components.AppPart2', function (structureInfo, getStyleData, siteData, loadedStyles) {
        const compData = structureInfo.dataItem;
        const appService = siteData.getClientSpecMapEntry(compData.appInnerID);

        if (dataFetchingStateManager.isPartErroneous(siteData, appService)) {
            return;
        }

        const descriptor = wixapps.wixappsDataHandler.getDescriptor(siteData, appService.type);
        const partDef = descriptor && appRepo.getAppPartDefinition(descriptor, compData.appPartName);
        const views = partDef && appRepo.getPartViews(descriptor, partDef, '*');
        if (views) {
            addAppPartStyles({getStyleData, views, loadedStyles});
        }
    });

    return addAppPartStyles;
});
