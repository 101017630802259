define(['wixappsBuilder/proxies/mixins/baseFieldProxy', 'lodash'],
    function (baseFieldProxy, _) {
        'use strict';

        const typeEmptyCheckers = {
            'wix:Image'(data) {
                return _.isEmpty(data.src) || data.src === 'http://images/noimage.png';
            },
            'String'(data) {
                return _.isEmpty(data);
            },
            'wix:Video'(data) {
                return _.isEmpty(data.videoId) || data.videoType === 'YOUTUBE' && data.videoId === 'xLk7JoPDx4Q'; // eslint-disable-line no-mixed-operators
            }
        };

        /**
         * @class proxies.field
         * @extends proxies.mixins.baseProxy
         * @property {proxy.properties} props
         */
        return {
            mixins: [baseFieldProxy],

            statics: {
                width: {type: 'compProp', defaultValue: 200},
                height: {type: 'compProp', defaultValue: 200}
            },

            shouldHide(data) {
                if (_.isUndefined(data)) {
                    return true;
                }
                const typeName = _.isString(data) ? 'String' : data._type;
                const checker = typeEmptyCheckers[typeName];
                return checker && checker(data);
            },

            getItemLayout() {
                const width = this.getCompProp('width') || 100;
                const height = this.getCompProp('height') || 100;
                const minWidth = this.getCompProp('layout') && this.getCompProp('layout')['min-width'] || 0; // eslint-disable-line no-mixed-operators

                const heightMode = this.getCompProp('heightMode') || 'manual';

                return {
                    minWidth: Math.max(minWidth, width),
                    height: heightMode === 'manual' ? height : 'auto'
                };
            }
        };
    });
