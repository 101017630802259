define(['lodash', 'wixappsCore', 'componentsCore', 'wixappsBuilder/core/builderDataHandler'], function (_, /** wixappsCore */ wixapps, componentsCore, builderDataHandler) {
    'use strict';

    const wixappsDataHandler = wixapps.wixappsDataHandler;

    /**
     * This will get the items and repo data for parts on the current page
     */
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForAllCompsOfType('wixapps.integration.components.AppPart2', function (siteData, compInfoArr, urlData) {
        // preview cannot use this API
        if (!siteData.isViewerMode()) {
            return [];
        }
        // all have the same appInnerID
        const appInnerId = compInfoArr[0].data.appInnerID;
        const appService = siteData.getClientSpecMapEntry(appInnerId);

        const allPartNames = _.map(compInfoArr, 'data.appPartName');

        const metadata = wixappsDataHandler.getPackageMetadata(siteData, appService.type);
        metadata.requestedPartNames = metadata.requestedPartNames || [];
        const partNames = _.difference(allPartNames, metadata.requestedPartNames);

        metadata.requestedPartNames = _.union(metadata.requestedPartNames, allPartNames);
        wixappsDataHandler.setPackageMetadata(metadata, siteData, appService.type);

        if (partNames.length) {
            return builderDataHandler.getBundledPartsDataRequest(siteData, appService, partNames, urlData);
        }

        if (wixappsDataHandler.didPackageHadRequestsTimeoutInSSR(siteData, appService.type) && !siteData.isInSSR()) {
            wixappsDataHandler.setPackageRequestTimedout(false, siteData, appService.type);
            return builderDataHandler.getBundledPartsDataRequest(siteData, appService, allPartNames, urlData);
        }

        return [];
    });
});

