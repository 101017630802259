define([
    'lodash',
    'wixappsCore',
    'wixappsBuilder/core/builderDataHandler',
    'wixappsBuilder/core/appPart2DataFetchingStateManager',
    'wixappsBuilder/core/appRepo',
    'componentsCore'
], function (
    _,
    /** wixappsCore */ wixapps,
    builderDataHandler,
    dataFetchingStateManager,
    appRepo,
    componentsCore
) {
    'use strict';

    /**
     * @type {core.core.dataRequirementsChecker}
     */
    const wixappsDataHandler = wixapps.wixappsDataHandler;

    function repoRequestGetter(siteData, appService) {
        // viewer should not use this API
        if (siteData.isViewerMode()) {
            return [];
        }
        const metadata = wixappsDataHandler.getPackageMetadata(siteData, appService.type);
        if (metadata.wasRepoRequested) {
            return [];
        }
        wixappsDataHandler.setPackageMetadata({wasRepoRequested: true, requestedPartNames: []}, siteData, appService.type);

        return builderDataHandler.getApplicationRepoRequest(siteData, appService);
    }

    function partRequestGetter(siteData, compInfo) { // eslint-disable-line complexity
        // viewer should not use this API
        if (siteData.isViewerMode()) {
            return [];
        }

        const appInnerId = compInfo.data.appInnerID;
        const appService = siteData.getClientSpecMapEntry(appInnerId);
        const partName = compInfo.data.appPartName;

        // no repo and no error - we wait
        const repo = wixappsDataHandler.getDescriptor(siteData, appService.type);
        if (!repo && !dataFetchingStateManager.isPackageErroneous(siteData, appService)) {
            dataFetchingStateManager.setPartLoadingState(siteData, appService, partName);
            return [];
        }
        // else repo was loaded or had error loading

        const metadata = wixappsDataHandler.getPackageMetadata(siteData, appService.type);

        metadata.requestedPartNames = metadata.requestedPartNames || [];
        if (_.includes(metadata.requestedPartNames, partName)) {
            return [];
        }
        metadata.requestedPartNames.push(partName);

        const partDef = appRepo.getAppPartDefinition(repo, partName);
        if (!partDef) {
            dataFetchingStateManager.setPartAsErroneous(siteData, appService, partName);
            return [];
        }

        const typeId = partDef.type;
        metadata.requestedTypes = metadata.requestedTypes || [];
        if (_.includes(metadata.requestedTypes, typeId)) {
            return [];
        }
        metadata.requestedTypes.push(typeId);

        wixappsDataHandler.setPackageMetadata(metadata, siteData, appService.type);

        return builderDataHandler.getAllItemsOfTypeRequest(siteData, appService, typeId) || [];
    }

    /**
     * This will make sure we always have the repo in the preview
     */
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForAppDefId('3d590cbc-4907-4cc4-b0b1-ddf2c5edf297', repoRequestGetter);

    /**
     * This will get the items for parts on current page in the preview
     */
    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wixapps.integration.components.AppPart2', partRequestGetter);

    return {
        partRequestGetter,
        repoRequestGetter
    };
});

