define(['wixappsCore'], function (wixapps) {
    'use strict';

    const wixappsDataHandler = wixapps.wixappsDataHandler;

    function setPackageLoadingState(siteData, appService) {
        wixappsDataHandler.setPackageMetadata({loading: true}, siteData, appService.type);
    }

    function clearPackageLoadingState(siteData, appService) {
        wixappsDataHandler.setPackageMetadata({loading: false}, siteData, appService.type);
    }

    function setPackageAsErroneous(siteData, appService) {
        clearPackageLoadingState(siteData, appService);
        wixappsDataHandler.setPackageMetadata({error: true}, siteData, appService.type);
    }

    function setPartLoadingState(siteData, appService, partName) {
        wixappsDataHandler.setCompMetadata({loading: true}, siteData, appService.type, partName);
    }

    function clearPartLoadingState(siteData, appService, partName) {
        wixappsDataHandler.setCompMetadata({loading: false}, siteData, appService.type, partName);
    }

    function setPartAsErroneous(siteData, appService, partName) {
        clearPartLoadingState(siteData, appService, partName);
        wixappsDataHandler.setCompMetadata({error: true}, siteData, appService.type, partName);
    }

    function hasPartLoadedSuccessfully(siteData, appService, partName) {
        const metadata = wixappsDataHandler.getCompMetadata(siteData, appService.type, partName);
        return metadata.loading === false && !metadata.error;
    }

    function hasPackageLoadedSuccessfully(siteData, appService) {
        const metadata = wixappsDataHandler.getPackageMetadata(siteData, appService.type);
        return metadata.loading === false && !metadata.error;
    }

    function isPartErroneous(siteData, appService, partName) {
        const metadata = wixappsDataHandler.getCompMetadata(siteData, appService.type, partName);
        return !!metadata.error;
    }

    function isPackageErroneous(siteData, appService) {
        const metadata = wixappsDataHandler.getPackageMetadata(siteData, appService.type);
        return !!metadata.error;
    }

    return {
        clearPackageLoadingState,
        setPackageAsErroneous,
        clearPartLoadingState,
        setPartAsErroneous,
        setPartLoadingState,
        setPackageLoadingState,
        hasPartLoadedSuccessfully,
        hasPackageLoadedSuccessfully,
        isPartErroneous,
        isPackageErroneous
    };
});