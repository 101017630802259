define([
    'prop-types',
    'santa-components'
], function (
    PropTypes,
    santaComponents
) {
    'use strict';

    const {utils: santaUtils} = santaComponents;

    return santaUtils.createSantaTypesDefinitions({
        appPartDefinition: PropTypes.object,
        dataSelector: PropTypes.object,
        dataSelectorDefinition: PropTypes.object,
        partDataLocation: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])),
        partData: PropTypes.arrayOf(PropTypes.object),
        views: PropTypes.object,
        compMetadata: PropTypes.object,
        isRequestSent: PropTypes.bool,
        didPackageHadRequestsTimeoutInSSR: PropTypes.bool
    }, 'listBuilderSantaTypes');
});
