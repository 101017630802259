define([
    'lodash',
    'wixappsCore',
    'santa-components',
    'wixappsBuilder/core/appBuilderSantaTypesDefinitions',
    'wixappsBuilder/core/appRepo'
], function (
    _,
    wixapps,
    santaComponents,
    appBuilderSantaTypesDefinitions,
    appRepo
) {
    'use strict';

    const createWixappsComponentFetcher = wixapps.wixappsSantaTypesUtil.createWixappsComponentFetcher;
    const wixappsCoreSantaTypesDefinitions = wixapps.CoreSantaTypes;

    function withTouchRenderCounter(func) {
        return (state, props) => {
            touchRenderCounter(state, props);
            return func(state, props);
        };
    }

    function touchRenderCounter(state, props, partDefinition) {
        partDefinition = partDefinition || getPartDefinition(state, props);
        if (partDefinition) {
            const displayDal = state.siteAPI.getDisplayedDAL();
            const pointers = state.siteAPI.getPointers();
            const packageName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
            const counterDataPointer = pointers.wixapps.getCounterData(packageName, partDefinition.type);

            const counter = displayDal.get(counterDataPointer);
            if (_.isUndefined(counter)) {
                displayDal.set(counterDataPointer, 0);
            }
            displayDal.get(counterDataPointer);
        }
    }

    function getPartDefinition(state, props) {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const repo = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.descriptor, state, props);

        return appRepo.getAppPartDefinition(repo, compData.appPartName);
    }

    const appPartDefinition = createWixappsComponentFetcher(withTouchRenderCounter(
        (state, props) => getPartDefinition(state, props))
    );

    const dataSelector = createWixappsComponentFetcher(withTouchRenderCounter((state, props) => {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const repo = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.descriptor, state, props);
        const appService = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.appService, state, props);

        return repo && appRepo.getDataSelector(repo, compData.appPartName, state.siteData, appService, repo.applicationInstanceVersion);
    }));

    const dataSelectorDefinition = createWixappsComponentFetcher(withTouchRenderCounter((state, props) => {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const desc = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.descriptor, state, props);

        return appRepo.getDataSelectorDefinition(desc, compData.appPartName);
    }));

    const partDataLocation = createWixappsComponentFetcher(withTouchRenderCounter((state, props) => {
        const selector = state.fetchSantaType(appBuilderSantaTypesDefinitions.dataSelector, state, props);
        return selector && selector.getData();
    }));

    const partData = createWixappsComponentFetcher(withTouchRenderCounter((state, props) => {
        const locations = state.fetchSantaType(appBuilderSantaTypesDefinitions.partDataLocation, state, props);
        const packageName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);

        if (locations && packageName) {
            const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
            return _(locations).filter(loc => _.isArray(loc))
                .map(location => dataAspect.getDataByPath(packageName, location))
                .value();
        }
    }));

    const views = createWixappsComponentFetcher((state, props) => {
        const repo = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.descriptor, state, props);

        if (!repo) {
            return null;
        }

        const formatName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.formatName, state, props);

        const partDefinition = state.fetchSantaType(appBuilderSantaTypesDefinitions.appPartDefinition, state, props);
        if (!partDefinition) {
            return {};
        }

        return appRepo.getPartViews(repo, partDefinition, formatName);
    });

    const compMetadata = createWixappsComponentFetcher((state, props) => {
        const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const packageName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);

        return dataAspect.getMetadata(packageName, compData.appPartName) || {};
    });

    const isRequestSent = createWixappsComponentFetcher((state, props) => {
        const compData = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        const packageName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);
        const packageMetaData = dataAspect.getMetadata(packageName) || {};
        return _.includes(packageMetaData.requestedPartNames, compData.appPartName);
    });

    const didPackageHadRequestsTimeoutInSSR = createWixappsComponentFetcher((state, props) => {
        const dataAspect = state.siteAPI.getSiteAspect('wixappsDataAspect');
        const packageName = state.fetchSantaType(wixappsCoreSantaTypesDefinitions.packageName, state, props);

        return !!dataAspect.didPackageHadRequestsTimeoutInSSR(packageName);
    });

    return {
        didPackageHadRequestsTimeoutInSSR,
        isRequestSent,
        partDataLocation,
        partData,
        appPartDefinition,
        views,
        dataSelector,
        dataSelectorDefinition,
        compMetadata
    };
});
