define([
    'lodash',
    'wixappsCore'
], function (_,
    /** wixappsCore */ wixapps) {
    'use strict';


    function getFontFamiliesFromViews(views) {
        const fontFamilies = [];
        _.forEach(views, function (viewDef) {
            wixapps.viewsUtils.traverseViews(viewDef, function (view) {
                if (view.comp.fontFamily) {
                    fontFamilies.push(view.comp.fontFamily);
                }
            });
        });
        return fontFamilies;
    }

    function getFontFamiliesFromTextData(partData) {
        if (!partData) {
            return null;
        }
        const ret = [];
        _.forEach(partData.items, function (componentData) {
            _.forEach(componentData, function (field) {
                const typeName = wixapps.typeNameResolver.getDataItemTypeName(field);
                if (_.includes(['wix:MediaRichText', 'wix:RichText'], typeName)) {
                    ret.push(field.text);
                }
            });
        });

        return ret;
    }

    function getCustomFontFamilies(descriptor) {
        let fontFamilies = [];
        if (descriptor && !_.isEmpty(descriptor.views)) {
            fontFamilies = fontFamilies.concat(getFontFamiliesFromViews(descriptor.views));
        }

        return fontFamilies;
    }

    return {
        getFontFamiliesFromTextData,
        getCustomFontFamilies
    };
});
